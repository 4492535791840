import {useContext, useMemo} from 'react';

import {WidgetConfig, useWallet} from '@lifi/widget';
import {useWeb3React} from '@web3-react/core';
import {NEW_LIQ_GLOBAL_NAME, RPC_URLS, SUPPORTED_NETWORKS, USDT_GLOBAL_NAME, lifiIntegrator} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useToken} from 'src/hooks';
import {useAppSelector} from 'src/state/hooks';
import {COLORS} from 'src/styles';

import {isWebView} from './utils';

export const useLifiWidget = () => {
  const {chainId, provider, account} = useWeb3React();
  const {disconnect, switchChain} = useWallet();
  const {sortedTokens, getTokenByGlobalName} = useToken();
  const {swapSettings} = useAppSelector((state) => state.user);
  const {userFee} = useContext(AppContext);

  const isMobile = isWebView() || window.innerWidth < 768;

  const liqToken = getTokenByGlobalName(NEW_LIQ_GLOBAL_NAME);
  const usdtToken = getTokenByGlobalName(USDT_GLOBAL_NAME);
  const allowedChains = Object.keys(SUPPORTED_NETWORKS).map((item) => Number(item));
  const fromToken = sortedTokens?.find((token) => token.isNative)?.address;
  const toToken = chainId === 56 ? liqToken?.address : usdtToken?.address;

  const config = useMemo<WidgetConfig>(
    () => ({
      integrator: lifiIntegrator,
      fee: userFee / 100,
      sdkConfig: {
        rpcs: RPC_URLS,
      },
      chains: {allow: allowedChains},
      fromChain: chainId,
      toChain: chainId,
      fromToken: fromToken,
      toToken: toToken,
      containerStyle: {
        borderRadius: '16px',
        paddingTop: '10px',
      },
      theme: {
        palette: {
          primary: {main: COLORS.SECONDARY},
          secondary: {main: COLORS.SECONDARY},
          background: {default: isMobile ? COLORS.GRAY_BG : COLORS.WHITE},
          text: {
            primary: COLORS.PRIMARY,
            secondary: COLORS.GRAY_TEXT,
            disabled: COLORS.DISABLED,
          },
        },
        typography: {
          fontFamily: 'Montserrat',
        },
      },
      variant: isMobile ? 'default' : 'expandable',
      subvariant: 'split',
      subvariantOptions: 'swap',
      hiddenUI: ['appearance', 'language', 'poweredBy'],
      appearance: 'light',
      routePriority: 'CHEAPEST',
      slippage: (swapSettings?.slippage?.value || 0.1) / 100,
      walletManagement: {
        signer: provider.getSigner(account),
        connect: async () => {
          const signer = provider.getSigner(account).connect(provider);
          return signer;
        },
        disconnect: async () => {
          disconnect();
        },
        switchChain: async (chainId: number) => {
          try {
            await switchChain(chainId);
            const signer = provider.getSigner(account);
            if (!signer) {
              throw new Error('Signer not available');
            }
            return signer;
          } catch (error) {
            //@ts-expect-error: error object has message
            console.error(`Chain switch failed: ${error?.message}`);
          }
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chainId, provider, account, sortedTokens, swapSettings, isMobile, userFee],
  );

  return {config};
};
