import {ConnectionType, useGetConnection} from 'src/connectors';

export const useWalletConnectMobile = () => {
  const getConnections = useGetConnection();

  const setUriItem = async () => {
    try {
      const connection = getConnections(ConnectionType.WALLET_CONNECT2);
      const activeSession = localStorage.getItem('activeSession');

      if (activeSession) {
        localStorage.removeItem('activeSession');
        connection.connector.connectEagerly();
        return;
      }

      const getUri = setInterval(() => {
        const provider = connection.connector.provider;
        //@ts-expect-error: the signer exist on provider after activation
        const uri = provider?.signer?.uri;
        if (provider && typeof uri === typeof 'string') {
          //@ts-expect-error: this is for the webview
          window.ReactNativeWebView.postMessage(uri);
          clearInterval(getUri);
        }
      }, 50);
      setTimeout(() => {
        if (getUri) clearInterval(getUri);
      }, 10000);
      await connection.connector.activate();
    } catch (e) {
      console.error(e);
    }
  };

  return {setUriItem};
};
