import {useEffect, useState} from 'react';

import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {createGlobalStyle} from 'styled-components';
import './pages/MarketOverview/index.css';

import 'react-toastify/dist/ReactToastify.css';
import '@reach/dialog/styles.css';
import ChatBotButton from './components/ChatBot/chatBotButton';
import ChatBotModal from './components/Modals/Chatbot/chatBotModal';
import {NavLayout} from './components/NavLayout';
import {paths} from './constants';
import {allowChatBot} from './constants/chatBot';
import {ModalsProvider} from './contexts/modals';
import {useScrollToTop} from './hooks';
import {Notfound} from './pages/404';
import ArticleView from './pages/ArticleView'; // Adjust the path as necessary
import {Bridge} from './pages/Bridge';
import {LifiWidget} from './pages/Bridge/widget';
import {EmergencyWithdraw} from './pages/EmergencyWithdraw';
import {Farm} from './pages/Farm';
import History from './pages/History';
import {Holdings} from './pages/Holdings';
import {Home} from './pages/Home';
import {Liquidity} from './pages/Liquidity';
import {AddLiquidity} from './pages/Liquidity/components/AddLiquidity';
import {PoolDetail} from './pages/PoolDetail';
import {PremiumDetail} from './pages/PremiumDetail';
import {Send} from './pages/Send';
import {Wallet} from './pages/Wallet';
import {YieldResult} from './pages/YieldResult';
import {YieldScan} from './pages/YieldScan';
import {isWebView} from './utils/utils';

// Custom hook for page tracking
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      const currentPath = location.pathname + location.search;
      window.gtag('config', 'G-52J2J4TSJD', {page_path: currentPath});
    }
  }, [location]);
};

const GlobalStyles = createGlobalStyle`
  body {
    font-family: Montserrat;
    margin: 0;
    overscroll-behavior-y: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  html, body, div {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  }
   
  ::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 9px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
`;

function App() {
  useScrollToTop();
  usePageTracking();

  const [isChatBotOpen, setIsChatBotOpen] = useState(false);

  const handleOpenChatBot = () => {
    setIsChatBotOpen(true);
  };

  const handleCloseChatBot = () => {
    setIsChatBotOpen(false);
  };

  return (
    <ModalsProvider>
      <NavLayout>
        <GlobalStyles />
        <Routes>
          <Route path={paths.yieldScanner} element={<YieldScan />} />
          <Route path={paths.yieldResult} element={<YieldResult />} />
          <Route path={paths.home} element={<Home />} />
          <Route path={paths.swap} element={<LifiWidget />} />
          <Route path={paths.bridge} element={<LifiWidget />} />
          <Route path={paths.holdings} element={<Holdings />} />
          <Route path={paths.history} element={<History />} />
          <Route path={paths.farm} element={<Farm />} />
          <Route path={paths.poolDetail} element={<PoolDetail />} />
          <Route path={paths.premiumDetail} element={<PremiumDetail />} />
          <Route path={paths.wallet} element={<Wallet />} />
          <Route path={paths.bridge} element={<Bridge />} />
          <Route path={paths.articleView} element={<ArticleView />} />
          <Route path={paths.send} element={<Send />} />
          <Route path={paths.root} element={<Navigate replace to='/home' />} />
          <Route path={paths.emergencyWithdraw} element={<EmergencyWithdraw />} />
          <Route path={paths.liquidity} element={<Liquidity />} />
          <Route path={paths.addLiquidity} element={<AddLiquidity />} />
          <Route path='*' element={<Notfound />} />
        </Routes>
        <ToastContainer />
        {allowChatBot && !isWebView() && (
          <>
            {!isChatBotOpen && <ChatBotButton onClick={handleOpenChatBot} />}
            <ChatBotModal isOpen={isChatBotOpen} onClose={handleCloseChatBot} />
          </>
        )}
      </NavLayout>
    </ModalsProvider>
  );
}

export default App;
