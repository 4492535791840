import {default as BN} from 'bn.js';
import {swapInputAdjustment} from 'src/constants/premiums';
import {ILiquidityPool} from 'src/types';

export const emailFormat = (value: string) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? `Invalid email address` : undefined;
};

export const isValidPassword = (string: string) => /[A-Z]/.test(string) && /[a-z]/.test(string) && /[0-9]/.test(string);

export const simplifyAddress = (address?: string, prefix = 4) => {
  return (
    address?.slice(0, 6) +
    '...' +
    address?.slice(address.length - (address.length > prefix ? prefix : 4), address.length)
  );
};

export const randomColor = () => {
  return '#' + Math.floor(0x100000 + Math.random() * 0xefff00).toString(16);
};

export const handleInputValue = (value: string) => {
  const numericValue = value.replace(/[^0-9.,]/g, '');
  const removedComma = numericValue.replace(/,/g, '.');
  const checkDots = removedComma.split('.');
  if (checkDots.length > 2) {
    return;
  }
  return removedComma;
};

export const makeBNumber = (amount: number, decimal: number) => {
  const str = amount.toString();
  const isDecimal = str.includes('.');
  const decimalNumber = isDecimal ? str.split('.')[1].length : 0;
  let amountInt = parseInt(str.split('.').join(''));
  let decimalb = 10 ** (decimal - decimalNumber);
  if (decimalNumber > decimal) {
    amountInt = amountInt / 10 ** (decimalNumber - decimal);
    decimalb = 1;
  }

  const decimals = new BN(decimalb.toString());
  const bn = new BN(new BN(amountInt).mul(decimals));
  return bn;
};

export const checkCurPage = (curPage: string, checkedPages: string[]) => {
  return checkedPages?.includes(curPage);
};

export const isTransactionSuccessful = (tx: unknown) => {
  //@ts-expect-error: status is present on tx property
  return tx?.status === 1 || tx?.status === true;
};

export const getAdjustmentPercent = (slippage: number) => {
  return slippage + swapInputAdjustment;
};

export const getVolume = (pool: ILiquidityPool) => {
  const volumeSum = pool?.pastVolumes?.reduce((prev, cur) => prev + cur, 0);
  const volumeLength = pool?.pastVolumes?.length;

  return volumeSum / volumeLength;
};

export const kFormatter = (value: number) => {
  if (Number.isNaN(value)) return undefined;

  const valueK = value / 1000;
  if (valueK > 10) return `${valueK.toFixed(2)}k`;
  else if (value % 2 !== 0) return value.toFixed(2);
  else return value;
};

export const parseScientific = (num: string): string => {
  // If the number is not in scientific notation return it as it is.
  if (!/\d+\.?\d*e[+-]*\d+/i.test(num)) {
    return num;
  }

  // Remove the sign.
  const numberSign = Math.sign(Number(num));
  num = Math.abs(Number(num)).toString();

  // Parse into coefficient and exponent.
  const [coefficient, exponent] = num.toLowerCase().split('e');
  let zeros = Math.abs(Number(exponent));
  const exponentSign = Math.sign(Number(exponent));
  const [integer, decimals] = (coefficient.indexOf('.') != -1 ? coefficient : `${coefficient}.`).split('.');

  if (exponentSign === -1) {
    zeros -= integer.length;
    num =
      zeros < 0
        ? integer.slice(0, zeros) + '.' + integer.slice(zeros) + decimals
        : '0.' + '0'.repeat(zeros) + integer + decimals;
  } else {
    if (decimals) zeros -= decimals.length;
    num =
      zeros < 0
        ? integer + decimals.slice(0, zeros) + '.' + decimals.slice(zeros)
        : integer + decimals + '0'.repeat(zeros);
  }

  return numberSign < 0 ? '-' + num : num;
};

export const priceToDecimalForm = (n: number) => {
  /**
   * Return the value as an object of value and decimals
   *
   * e.g => 3.44 is 344 as value and 2 as decimals, so 344 * 10^-2
   * **/
  const stringValue = parseScientific(n.toString());
  const splitValue = stringValue.split('.');
  let decimals;
  let value;

  if (splitValue.length === 1) {
    decimals = 0;
    value = n;
  } else {
    decimals = splitValue[1].length;
    value = parseInt(splitValue.join(''));
  }

  return {
    value,
    decimals,
  };
};

export const isWebView = () => {
  const webView = sessionStorage.getItem('liq_webView');
  if (webView && webView === 'true') {
    return true;
  } else return false;
};
