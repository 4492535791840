import React from 'react';

import styled from 'styled-components';

import ChatBotIcon from '../Svgs/ChatbotIcon';

const ChatBotButton: React.FC<{onClick: () => void}> = ({onClick}) => {
  return (
    <Button onClick={onClick}>
      <ChatBotIcon width={60} height={60} />
    </Button>
  );
};

export default ChatBotButton;

const Button = styled.button`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
