import {useEffect} from 'react';

import {LiFiWidget} from '@lifi/widget';
import {useWeb3React} from '@web3-react/core';
import {lifiIntegrator} from 'src/constants';
import {useWalletConnectMobile} from 'src/hooks/useWalletConnectMobile';
import {DEVICE_ENUM} from 'src/styles';
import {useLifiWidget} from 'src/utils/lifi';
import {isWebView} from 'src/utils/utils';
import styled from 'styled-components';

export const LifiWidget = () => {
  const {config} = useLifiWidget();
  const {account} = useWeb3React();
  const {setUriItem} = useWalletConnectMobile();
  const webView = isWebView();

  const rootStyle = document.getElementById('root').style;

  useEffect(() => {
    if (webView) {
      rootStyle.position = 'relative';
      rootStyle.zIndex = '90';
      const sendUri = async () => {
        await setUriItem();
      };
      sendUri();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account && webView) {
      rootStyle.removeProperty('position');
      rootStyle.removeProperty('zIndex');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, webView]);

  return (
    <Wrapper>
      <Container>
        <LiFiWidget config={config} integrator={lifiIntegrator} />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 0 16px;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    height: auto;
    overflow: auto;
  }
`;

const Container = styled.div`
  border-radius: 24px;
  padding: 34px 40px;
  overflow: hidden;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 24px 20px;
  }
`;
